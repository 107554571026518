const vertexShaderSource = require('./vert.glsl');
const fragmentShaderSource = require('./frag.glsl');

const canvases = document.querySelectorAll('.halftone');
[...canvases].forEach(halftone);

function halftone(canvas: HTMLCanvasElement) {
  canvas.style.backgroundImage = 'none';

  const startColor = [246, 227, 213, 1];
  const endColor = [0, 0, 0, 0];

  // Originally, I used { premultipliedAlpha: true }, but that was inconsistent
  // between operating systems.
  const gl = canvas.getContext('webgl');

  const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
  const fragmentShader = createShader(
    gl,
    gl.FRAGMENT_SHADER,
    fragmentShaderSource
  );

  const program = createProgram(gl, vertexShader, fragmentShader);

  const resolutionUniformLocation = gl.getUniformLocation(
    program,
    'u_resolution'
  );
  const startColorUniformLocation = gl.getUniformLocation(
    program,
    'u_start_color'
  );
  const endColorUniformLocation = gl.getUniformLocation(program, 'u_end_color');
  const scaleUniformLocation = gl.getUniformLocation(program, 'u_scale');
  const positionAttributeLocation = gl.getAttribLocation(program, 'a_position');
  const positionBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  const positions = [-1, 1, -1, -1, 1, 1, 1, -1, 1, 1, -1, -1];
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

  gl.clearColor(0, 0, 0, 1);
  gl.clear(gl.COLOR_BUFFER_BIT);

  gl.useProgram(program);
  gl.uniform4f(
    startColorUniformLocation,
    startColor[0] / 255,
    startColor[1] / 255,
    startColor[2] / 255,
    startColor[3]
  );
  gl.uniform4f(
    endColorUniformLocation,
    endColor[0] / 255,
    endColor[1] / 255,
    endColor[2] / 255,
    endColor[3]
  );
  gl.enableVertexAttribArray(positionAttributeLocation);

  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.vertexAttribPointer(positionAttributeLocation, 2, gl.FLOAT, false, 0, 0);

  function updateDimensions() {
    const { width, height } = canvas.getBoundingClientRect();
    const scale = window.devicePixelRatio;
    canvas.width = width * scale;
    canvas.height = height * scale;
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.uniform2f(resolutionUniformLocation, gl.canvas.width, gl.canvas.height);
    gl.uniform1f(scaleUniformLocation, scale);
  }
  updateDimensions();

  function render() {
    gl.drawArrays(gl.TRIANGLES, 0, 6);
  }
  render();

  const resizeObserver = new ResizeObserver(() => {
    updateDimensions();
    render();
  });
  resizeObserver.observe(canvas);
}

function createShader(
  gl: WebGLRenderingContext,
  type: number,
  source: string
): WebGLShader {
  const shader = gl.createShader(type);
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
  if (success) return shader;

  const infoLog = gl.getShaderInfoLog(shader);
  gl.deleteShader(shader);
  throw new Error(infoLog);
}

function createProgram(
  gl: WebGLRenderingContext,
  vertexShader: WebGLShader,
  fragmentShader: WebGLShader
): WebGLProgram {
  const program = gl.createProgram();
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  const success = gl.getProgramParameter(program, gl.LINK_STATUS);
  if (success) return program;

  const infoLog = gl.getProgramInfoLog(program);
  gl.deleteProgram(program);
  throw new Error(infoLog);
}
